// Libs
import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';

// Components
import SEO from 'components/seo';
import ModalDocs from 'components/faq/modalDocs';

// Images

//Styles
const ContainerModal = styled.section`
  display: flex;
`;

const getMarkdown = (page, fragmentName, isMarkdown = false) => {
  const { fragments } = page[0];

  const findFragment = fragments.find(item => item.name === fragmentName);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const Modal = ({ page }) => {
  return (
    <>
      <SEO dataSeo={page} />
      <ContainerModal>
        <ModalDocs
          markdown={getMarkdown(
            page,
            'canaisHSLFaqTabMedicoAboutSirioModal',
            true
          )}
        />
      </ContainerModal>
    </>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckhp9bjg80bf40a24nl2biwv3" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Modal page={response.gcms.site.pages} />;
      }}
    />
  );
};
