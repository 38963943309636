// Libs
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { navigate } from 'gatsby';

// Components
import { device } from '../device';

// Images
import close from 'images/icons/close.svg';

// Styles
import 'components/layout.css';

const BackButton = styled.button`
  background: transparent;
  border: none;
  line-height: 0;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: grid;
  align-items: center;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(35, 31, 32, 0.5);
  font-size: 14px;
  font-family: 'Inter', sans-serif;

  li {
    margin-bottom: 0;
    line-height: 22px;
    > a {
      justify-content: space-between;
      width: 100%;
      color: #45a7df;
      :visited {
        color: #45a7df;
      }
    }
  }

  @media ${device.laptop} {
    display: block;
    padding-top: 0;
  }
`;

const Content = styled.section`
  grid-column: 4 / 12;
  column-gap: 43px;
  height: 674px;
  padding: 0 1.875rem 1.875rem 1.875rem;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  > ul {
    margin: 0;
    font-size: 14px;

    @media ${device.laptop} {
      margin-top: 0;
    }

    > li {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 1.375rem 1.25rem;
      color: #231f20;
      font-size: 14px;

      @media ${device.laptop} {
        padding: 1.5rem 1.25rem;
      }
    }
  }

  @media ${device.laptop} {
    grid-column: 3 / 11;
    margin-bottom: 1.875rem;
  }

  @media ${device.laptop} {
    height: 100vh;
    padding: 0;
    border-radius: 0;
  }
`;

const ContainerDescription = styled.main`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 44.375rem;
  height: 5.563rem;
  margin-bottom: 20px;
  padding: 1.875rem 0;
  background-color: #fff;
  border-bottom: 1px solid #c6c8cc;

  @media ${device.laptop} {
    position: initial;
    width: auto;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const Subtitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  color: #231f20;

  @media ${device.laptop} {
    width: 270px;
    padding-left: 1.25rem;
    font-size: 1.125rem;
    font-weight: 500;
  }
`;

const Icon = styled.img`
  cursor: pointer;

  @media ${device.laptop} {
    display: none;
  }
`;

const Menu = styled.div`
  position: fixed;
  display: none;

  @media ${device.laptop} {
    z-index: 5;
    display: flex;
    align-items: center;
    height: 96px;
    width: 100vw;
    padding: 0 20px;
    background: #fff;
    border-bottom: 1px solid #c6c8cc;
  }
`;

const IconMobile = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`;

const ItemsModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  > li {
    font-size: 14px;
  }
  > h3 {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 22px;
  }

  @media ${device.laptop} {
    margin: 0 0 30px;
    padding: 0 20px;
  }
`;

function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

const ModalDocsRequires = ({ markdown }) => {
  return (
    <Overlay>
      <Content onClick={ev => ev.stopPropagation()}>
        <Menu>
          <BackButton onClick={() => navigate(-1)}>
            <IconMobile src={close} alt="Fechar" />
          </BackButton>
        </Menu>
        <ContainerDescription>
          <Subtitle>Relação de documentos para o corpo clínico</Subtitle>
          <BackButton onClick={() => navigate(-1)}>
            <Icon src={close} alt="Fechar" />
          </BackButton>
        </ContainerDescription>
        <ItemsModal>
          <ReactMarkdown
            renderers={{ link: LinkRenderer }}
            children={markdown}
          />
        </ItemsModal>
      </Content>
    </Overlay>
  );
};

export default ModalDocsRequires;
